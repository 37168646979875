// Original modified =  09-17-2011
// Modified by =  MB
// DrupalGem modifications = 05/26/14
// Modified by =  PC

var lpMTagConfig = {
	'lpServer' : 'server.iad.liveperson.net',
	'lpNumber' : '24631554',
        //'lpNumber' : '74547244',
	'lpProtocol' : (document.location.toString().indexOf('https:')==0) ? 'https' : 'http',
	'lpTagLoaded' : false,
	//'lpTagSrv' : 'sr2.liveperson.net', //uncomment for hosted solution (erase this line if not needed)
	'pageStartTime' : (new Date()).getTime(), //pageStartTime is set with a timestamp as soon as the page starts loading
	'defaultUnit' : 'Estee Lauder'
};
if (typeof(lpMTagConfig.lpTagSrv) == 'undefined') {
	lpMTagConfig.lpTagSrv = lpMTagConfig.lpServer;
}
lpMTagConfig.deploymentConfigPath = lpMTagConfig.lpTagSrv+'/visitor/addons/deploy.asp';

lpMTagConfig.lpLoadScripts = function(){
	lpAddMonitorTag(lpMTagConfig.lpProtocol + '://' + lpMTagConfig.deploymentConfigPath + '?site=' + lpMTagConfig.lpNumber + '&d_id=' + lpMTagConfig.deploymentID);
}

function lpAddMonitorTag(src) {
	if (!lpMTagConfig.lpTagLoaded) {if (typeof(src) == 'undefined' || typeof(src) == 'object') {if (lpMTagConfig.lpMTagSrc) {src = lpMTagConfig.lpMTagSrc;}else {if (lpMTagConfig.lpTagSrv) {src = lpMTagConfig.lpProtocol + '://' +lpMTagConfig.lpTagSrv + '/hcp/html/mTag.js';}else {src = '/hcp/html/mTag.js';};};};if (src.indexOf('http') != 0) {src = lpMTagConfig.lpProtocol + '://' + lpMTagConfig.lpServer + src + '?site=' + lpMTagConfig.lpNumber;} else {if (src.indexOf('site=') < 0) {if (src.indexOf('?') < 0) {src = src + '?';} else{src = src + '&';} src = src + 'site=' + lpMTagConfig.lpNumber;  };};var s = document.createElement('script');s.setAttribute('type', 'text/javascript');s.setAttribute('charset', 'iso-8859-1');s.setAttribute('src', src);document.getElementsByTagName('head').item(0).appendChild(s);}
}

//The code below send a PAGEVAR to LP with the time [in seconds] it took the page to load. Code is executed in the onload event
lpMTagConfig.calculateSentPageTime = function () {
	var t = (new Date()).getTime() - lpMTagConfig.pageStartTime;
	lpAddVars('page','pageLoadTime', Math.round(t/1000)+' sec');
};

//Variables Arrays - By Scope
if (typeof(lpMTagConfig.pageVar)=='undefined') { lpMTagConfig.pageVar = []; }
if (typeof(lpMTagConfig.sessionVar)=='undefined') { lpMTagConfig.sessionVar = []; }
if (typeof(lpMTagConfig.visitorVar)=='undefined') { lpMTagConfig.visitorVar = []; }
//Extra actions to be taken once the code executes
if (typeof(lpMTagConfig.onLoadCode)=='undefined') { lpMTagConfig.onLoadCode = []; }
//Dynamic Buttons Array
if(typeof(lpMTagConfig.dynButton)=='undefined') { lpMTagConfig.dynButton = []; }
// This need to be add to afterStartPage will work
if(typeof(lpMTagConfig.ifVisitorCode)=='undefined') {lpMTagConfig.ifVisitorCode = []; }


// Function that sends variables to LP - By Scope
function lpAddVars(scope,name,value) {
	if (name.indexOf('OrderTotal')!=-1 || name.indexOf('OrderNumber')!=-1){
		if  (value=='' || value==0) return; // pass 0 value to all but OrderTotal
		else lpMTagConfig.sendCookies = false
	}
	value=lpTrimSpaces(value.toString());
//Remove cut long variables names and values. Trims suffix of the variable name above the 25th character onwards
	if (name.length>50) {
		name=name.substr(0,50);
	}
    if (value.length>50) { // Trims suffix of the variable value above the 50th character onwards
		value=value.substr(0,50);
	}
	switch (scope){
		case 'page': lpMTagConfig.pageVar[lpMTagConfig.pageVar.length] = escape(name)+'='+escape(value); break;
		case 'session': lpMTagConfig.sessionVar[lpMTagConfig.sessionVar.length] = escape(name)+'='+escape(value); break;
		case 'visitor': lpMTagConfig.visitorVar[lpMTagConfig.visitorVar.length] = escape(name)+'='+escape(value); break;
	}
}

// Preventing long cookie transfer for IE based browsers.
function onloadEMT() {
	var LPcookieLengthTest=document.cookie;
	if (lpMTag.lpBrowser == 'IE'){
	 if (LPcookieLengthTest.length>1000){
		lpMTagConfig.sendCookies=false;
	 }
	 lpMTagConfig.dynButton[lpMTagConfig.dynButton.length] = {'name':'chat-'+lpUnit+'-product','pid':'lpbuttonproductIE','afterStartPage': true};
   lpMTagConfig.dynButton[lpMTagConfig.dynButton.length] = {'name':'chat-'+lpUnit+'-ordertech','pid':'lpbuttonordertechIE','afterStartPage': true};
	}
}

//The Trim function returns a text value with the leading and trailing spaces removed
function lpTrimSpaces(stringToTrim) {
	return stringToTrim.replace(/^\s+|\s+$/g,'');
}

// Immediate Data submission function
function lpSendData(varscope,varname,varvalue) {
	if(typeof(lpMTag)!='undefined' && typeof(lpMTag.lpSendData)!='undefined')
		lpMTag.lpSendData(varscope.toUpperCase() +'VAR!'+ varname + '=' + varvalue, true);
}

// The unit variable purpose is to route the chat or call to the designated skill. <LOB> should be replaced with the skill name, i.e. : sales
try{
	if (typeof(lpUnit)=='undefined') { var lpUnit=lpMTagConfig.defaultUnit; }
	lpMTagConfig.deploymentID=lpUnit;
	if(typeof(lpAddVars)!='undefined') { lpAddVars('page','unit',lpUnit); }
	lpMTagConfig.defaultInvite='chat'+'-'+lpUnit;
}catch(e){}

lpMTagConfig.onLoadCode[lpMTagConfig.onLoadCode.length] = onloadEMT;

//Scan dynButton and removes buttons which doesnt have Div on the page
lpMTagConfig.onLoadCode[lpMTagConfig.onLoadCode.length] = function () {
	if(typeof(lpMTagConfig.dynButton)!='undefined') {
		for (i=0;i<lpMTagConfig.dynButton.length;i++){
			if (typeof(lpMTagConfig.dynButton[i].pid)!='undefined' && document.getElementById(lpMTagConfig.dynButton[i].pid) == null) {
					lpMTagConfig.dynButton.splice(i,1);
					i--;
			}
		}
	}
};


function lpTriggerEvent (pgEventName, lpEventName, lpEventData) {
    var eventData = lpEventData || {};
    eventData.lpEventName = lpEventName;

    if (typeof document.fire === "function") { document.fire(pgEventName, eventData); }
    else if (typeof jQuery != "undefined") { jQuery(document).trigger(pgEventName, [ eventData ]); }
}

function lpEventButtonClicked (eventName, eventData) {
    lpTriggerEvent('livechat:button_clicked', eventName, eventData);
}
function lpEventInviteAccepted (eventName, eventData) {
    lpTriggerEvent('livechat:invite_accepted', eventName, eventData);
}
function lpEventInviteShown (eventName, eventData) {
    lpTriggerEvent('livechat:invite_shown', eventName, eventData);
}

lpMTagConfig.onLoadCode[lpMTagConfig.onLoadCode.length]  = function () {
    lpTriggerEvent('livechat:loaded', '', {} );
    lpMTag.events.register('LP_INV_SHOWN', lpEventInviteShown);
    lpMTag.events.register('LP_INV_ACCEPT', lpEventInviteAccepted);
    lpMTag.events.register('LP_DYNBUTTON_CLICKED', lpEventButtonClicked);
    lpMTag.events.register('LP_STATBUTTON_CLICKED', lpEventButtonClicked);
};

//The folowing functions will be load after the page will finish loading
lpMTagConfig.onLoadAll = function () {
	lpMTagConfig.calculateSentPageTime();
	lpMTagConfig.lpLoadScripts();

        $(function(){

            //lpbuttonproduct
            $("#lpbuttonproduct").on("click", function(evt){
                //analytics
                lpTriggerEvent('livechat:button_clicked','LP_DYNBUTTON_CLICKED', { 'name':'chat-'+lpUnit+'-product' });
            });
        
            //lpbuttonordertech
            $("#lpbuttonordertech").on("click", function(evt){
                //analytics
                lpTriggerEvent('livechat:button_clicked','LP_DYNBUTTON_CLICKED', { 'name':'chat-'+lpUnit+'-ordertech' });
            });
        
            //lpbuttonfooter
            $("#lpbuttonfooter").on("click", function(evt){
                //analytics
                lpTriggerEvent('livechat:button_clicked','LP_DYNBUTTON_CLICKED', { 'name':'chat-'+lpUnit+'-footer' });
            });
        });

};

if (window.attachEvent) { //it's IE
	window.attachEvent('onload',lpMTagConfig.onLoadAll);

	// workaround for IE only issues with updating the nodes in the dropdown
	window.attachEvent('onload',function(){
	 	var liveChatForIEChecker = setInterval(checkLPDivContentUpdate, 100);
	  function checkLPDivContentUpdate(){
	 		// These 2 divs are created in footer.tmpl, then updated in mtagconfig.js, and moved into the dropdown
	 		var lpPIE = document.getElementById('lpbuttonproductIE');
	  	var lpOTIE = document.getElementById('lpbuttonordertechIE');

	  	if (lpPIE && lpPIE.innerHTML.match('actionHook') && lpOTIE && lpOTIE.innerHTML.match('actionHook') ){
	  		// For lpbuttonproduct
	  		var P = document.getElementById('lpbuttonproduct');
   			if (typeof(P) != 'undefined' && P != null){
   				P.appendChild(lpPIE.parentNode.removeChild(lpPIE));
					lpPIE.style.top = '0px';
	 			}

	 			// For lpbuttonordertech
	  		var OT = document.getElementById('lpbuttonordertech');
   			if (typeof(OT) != 'undefined' && OT != null){
   				OT.appendChild(lpOTIE.parentNode.removeChild(lpOTIE));
	 				lpOTIE.style.top = '0px';
	 		  }

	 		  // Stop checking
	 			clearInterval(liveChatForIEChecker);
	 		}
	 }
 	});

} else {
	window.addEventListener('load',lpMTagConfig.onLoadAll,false);
}

// LP Button Code
lpMTagConfig.dynButton[lpMTagConfig.dynButton.length] = {'name':'chat-'+lpUnit,'pid':'lpbuttondiv','afterStartPage': true};
lpMTagConfig.dynButton[lpMTagConfig.dynButton.length] = {'name':'chat-'+lpUnit+'-footer','pid':'lpbuttonfooter','afterStartPage': true};
lpMTagConfig.dynButton[lpMTagConfig.dynButton.length] = {'name':'chat-'+lpUnit+'-product','pid':'lpbuttonproduct','afterStartPage': true};
lpMTagConfig.dynButton[lpMTagConfig.dynButton.length] = {'name':'chat-'+lpUnit+'-ordertech','pid':'lpbuttonordertech','afterStartPage': true};



lpMTagConfig.delegate = {};
/*
lpMTagConfig.delegate.launchWindow = function() {

        var first_name = 'FIRST-NAME'; //jQuery('#input-first-name').val();
        var email = 'EMAIL@ADDRESS.COM'; //jQuery('#input-email-address').val();
        var textarea = 'A long and short summary of text to demonstrate pre-chat survey text messages'; //jQuery('#textarea-your-question').val();

        var window_loc = '/customer_service/vba/vba_window.tmpl?' + 'email=' + email + '&' + 'name=' + first_name + '&' + 'message=' + textarea;
        myWindow = window.open(window_loc,"MsgWindow","width=828,height=670,resizable=0");

};
*/

/* Called when the user accepts the proactive chat invitation */
lpMTagConfig.delegate.proactiveAccepted = function() {
    lpTriggerEvent('livechat:invite_accepted', 'LP_INV_ACCEPT', {});
    lpMTagConfig.delegate.prechatWindow();
};

/* Called when the user initiates chat (from the bottom drawer, the CS pages, etc) */
lpMTagConfig.delegate.prechatWindow = function() {

        /* works with colorbox prechat window - callback upon prechat window content load */
        $(document).on('cbox_complete', function(){

            // launch functions to arrive with prechat 
            jQuery('a.order-question-btn-active').on('click',launchSimpleWindow);
            jQuery('a.beauty-advice-btn-active').on('click',launchFullWindow);

            // redundant
            jQuery('.order-question-btn-off','#prechat_container').hide().unbind('click');
            jQuery('.beauty-advice-btn-off','#prechat_container').hide().unbind('click');

            /*
            cbox_open   triggers when Colorbox is first opened, but after a few key variable assignments take place.
            cbox_load   triggers at the start of the phase where content type is determined and loaded.
            cbox_complete       triggers when the transition has completed and the newly loaded content has been revealed.
            cbox_cleanup        triggers as the close method begins.
            cbox_closed         triggers as the close method ends. 
            */
        });


        jQuery.ajax({
            url: '/customer_service/vba/prechat_overlay.tmpl',
            context: document.body
        }).done(function(html) {

            var formContainerNode = jQuery('<div />').attr('id',"prechat_container");
            formContainerNode.html(html);

            generic.overlay.launch({content: formContainerNode, includeBackground: true, cssStyle:{width:"540px",height:"520px"}});

            // IE8 Prechat Overlay
            if (generic.env.isIE8) {
                jQuery.colorbox.resize({height:"600px",width:"540px"});
            } else {
                jQuery.colorbox.resize({height:"520px",width:"540px"});
            }

            jQuery('#prechat_container').parent().css({overflow:'hidden'});

            //IE9 Chat Button Enable
            if (generic.env.isIE9) {
                lpMTagConfig.onLoadAll();
            }

            lpTriggerEvent('livechat:;prechat_survey_displayed','LP_PRECHAT_SURVEY');

        });
        /* */

};
